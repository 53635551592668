
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        











































































@import '@/styles/_colors.scss';

.breadcrumbs {
  position: sticky;
  top: 4rem;
  z-index: 10;
  margin-bottom: 1rem;
  background: white;
  box-shadow: 0 3px 5px rgba(black, 0.1);
}

.breadcrumbs__nav {
  @include container();
}

.breadcrumbs__list {
  display: flex;
  @media screen and (min-width: bp(m)) {
    margin-left: span(3, 1);
  }

  @media screen and (min-width: bp(l)) {
    margin-left: span(2, 1);
  }
}

.breadcrumbs__item {
  display: none;

  &:nth-of-type(1) {
    display: block;
  }

  @media screen and (min-width: bp(xs)) {
    display: block;
  }
}

.breadcrumbs__link {
  position: relative;
  display: block;
  max-width: none;
  padding: 0.5rem;
  overflow: hidden;
  color: var(--color-text);
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;

  &:hover {
    background: rgba($color-grey-500, 0.1);
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 3px;
    content: '';
  }
}

.breadcrumbs__icon {
  margin-right: 0.25rem;
}

.breadcrumbs__list {
  @for $c from 1 through 6 {
    &[data-count='#{$c}'] .breadcrumbs__link {
      max-width: #{24 - ($c * 3)}rem;

      @media screen and (min-width: bp(s)) {
        max-width: #{26 - ($c * 3)}rem;
      }

      @media screen and (min-width: bp(m)) {
        max-width: #{28 - ($c * 3)}rem;
      }

      @media screen and (min-width: bp(l)) {
        max-width: #{32 - ($c * 2.5)}rem;
      }
    }
  }
}
